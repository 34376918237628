import { ReactNode, useRef, useEffect } from 'react'

interface Props {
  messages?: ReactNode
  inputChat?: ReactNode
}

import styles from './Ligia.module.css'

export const ChatContainer = ({ messages, inputChat }: Props) => {
  const chatEndRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  return (
    <div className={styles.chat}>
      <div className={`flex flex-col ${styles.chatContainer}`}>
        <div className="flex-1 overflow-y-auto">
          {messages}
          <div ref={chatEndRef} />
        </div>
        {inputChat}
      </div>
    </div>
  )
}
