import type { Citation, ExecResults, ChatMessage, AzureSqlServerCodeExecResult } from '../../api/models'

import { useState } from 'react'
import * as _ from 'lodash-es'

import imgDemo from '../../assets/img/AA.png'
import imgDemoTopo from '../../assets/img/demo-topo.png'
import svgLogo from '../../assets/svg/logo-ligia.svg'
import { Answer } from '../Answer'

interface Props {
  onSend: (question: string, id?: string) => void
  onClearChat: () => void
  onShowCitation: (data: Citation) => void
  onShowExecResult: () => void
  onParseCitationFromMessage: (data: ChatMessage) => Citation[]
  onParsePlotFromMessage: (data: ChatMessage) => AzureSqlServerCodeExecResult | null
  execResults: ExecResults[]
  isLoading: boolean
  placeholder?: string
  conversationId?: string
  messages: ChatMessage[]
}

import styles from './Ligia.module.css'

import { ChatInput } from './ChatInput'
import { ChatContainer } from './ChatContainer'
import { ChatMsg } from './ChatMsg'
export const Ligia = ({
  onSend,
  onClearChat,
  onShowCitation,
  onShowExecResult,
  onParseCitationFromMessage,
  onParsePlotFromMessage,
  execResults,
  isLoading,
  placeholder,
  conversationId,
  messages
}: Props) => {
  const [toggle, setToggle] = useState(false)
  const [files, setFiles] = useState<FileList>()

  const onMessageChange = ({ message, files }: { message: string; files?: FileList }) => {
    if (message.trim()) {
      if (conversationId) {
        onSend(message, conversationId)
      } else {
        onSend(message)
      }
    }

    if (files) {
      setFiles(files)
    }
  }

  return (
    <div className={styles.demo}>
      <div className={styles.demoTopo} />
      <div className={styles.demoImagem} style={{ backgroundImage: `url(${imgDemo})` }} />
      <div className="absolute bottom-2 right-4 flex justify-end pb-10">
        <div className="flex gap-3 p-2">
          {toggle && (
            <ChatContainer
              messages={
                <div className="flex flex-col gap-3 p-2">
                  {_.map(
                    messages,
                    (msg, index) =>
                      !_.isEmpty(msg) && (
                        <ChatMsg
                          key={index}
                          message={msg.content}
                          role={msg.role}
                          messageTemplate={
                            <Answer
                              answer={{
                                answer:
                                  `${msg.content}`.toLowerCase().search('the requested information') !== -1
                                    ? 'Desculpe, não consegui encontrar a informação que você pediu. Talvez eu possa ajudar de outra forma! Se quiser reformular a pergunta, estou à disposição.'
                                    : msg.content,
                                citations: onParseCitationFromMessage(messages[index - 1]),
                                plotly_data: onParsePlotFromMessage(messages[index - 1]),
                                message_id: msg.id,
                                feedback: msg.feedback,
                                exec_results: execResults
                              }}
                              onCitationClicked={c => onShowCitation(c)}
                              onExectResultClicked={() => onShowExecResult()}
                            />
                          }
                        />
                      )
                  )}
                  {files && <ChatMsg role="user" message="" files={files} />}
                </div>
              }
              inputChat={
                <ChatInput
                  onMessageChange={onMessageChange}
                  onClearChat={onClearChat}
                  placeholder={placeholder}
                  isLoading={isLoading}
                />
              }
            />
          )}
          <div className="relative  self-end">
            {!toggle && <div className={styles.ligiaOffAnimate} />}

            <div className={`${toggle ? 'shadow-xl' : ''} ${styles.ligia}`} onClick={() => setToggle(!toggle)}>
              <img src={svgLogo} alt="logo Ligia" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
